import nextId from "react-id-generator" 

export const links = [
    {
        id: nextId(),
        url: "#home",
        text: "home"
    },
    {
        id: nextId(),
        url: "#about",
        text: "o meni"
    },
    {
        id: nextId(),
        url: "#contact",
        text: "kontakt"
    },
]